html{
    height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;

  background-color:#eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
    top:0;
    bottom:0;
    left:0;
    right:0;
}

.fullCenter{
	top:0;left:0;bottom:0;right:0;
	display: grid;
	overflow-x: scroll;
}

.split{
	/* background-color: #eee; */
	padding: 20px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	margin:auto;
}

.item{
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
}

.item.fullsize{
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 4px 4px 20px 0px rgba(0,0,0,0.5);
	padding: 20px;
	margin: 20px;
	width: 200px; height: 200px;
}
.fileupload{

	border: 10px dashed #aaa;
	width: 100%;
	height: 100%;
	box-sizing:border-box;
}

@media only screen and (max-width: 700px) {
	.split{
		flex-direction: column;
	}
}
.center{
	text-align: center;
}

.versionerror{
	border-radius:10px;
	border: 1px solid #f00;
	background-color: rgba(255, 0, 0, 0.3);
	padding: 10px;
	margin: 10px;
}

